import { i18n } from 'app/constants/languages'

export const getLanguageHref = ({
  href,
  language,
  locale,
}: {
  href: string
  language: string
  locale: string | string[]
}) => {
  if (href === '/') {
    return '/'
  }

  const hrefWithoutLocale = href?.replace(`/${locale}/`, '/')

  if (language) {
    return `/${language}${hrefWithoutLocale}`
  }

  return `${locale === i18n.defaultLocale ? '' : `/${locale}`}${hrefWithoutLocale}`
}
