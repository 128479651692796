'use client'

import type { ReactNode } from 'react'
import { createContext, memo, useContext, useMemo } from 'react'
import { useAppSelector } from 'store/hooks'
import { getSentWelcomeEmailFieldSelector, isLoggedInSelector } from 'store/modules/user/selectors'
import { isAppInitializedSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import type { AuthCookies } from 'app/utils/getAuthCookies'

type AuthContextType = {
  isScEnabled: boolean
  isLoggedIn: boolean
  isSCBannerClicked: boolean
  isWelcomeEmailSubmited: boolean
}

const AuthContext = createContext<AuthContextType>(null)

interface TranslationsProviderProps {
  children: ReactNode
  authCookies: AuthCookies
}

function AuthProvider({ children, authCookies }: TranslationsProviderProps) {
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const isLoggedIn = useAppSelector(isLoggedInSelector)
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const isWelcomeEmailSubmited = useAppSelector(getSentWelcomeEmailFieldSelector)

  const {
    isLoggedInFromCookies,
    isScEnabledFromCookies,
    isSCBannerClickedFromCookies,
    isWelcomeEmailSubmitedFromCookies,
  } = authCookies

  const value = useMemo(() => {
    return {
      isScEnabled: appInitialized ? isScEnabled : isScEnabledFromCookies,
      isLoggedIn: appInitialized ? isLoggedIn : isLoggedInFromCookies,
      isSCBannerClicked: isSCBannerClickedFromCookies,
      isWelcomeEmailSubmited: appInitialized ? isWelcomeEmailSubmited : isWelcomeEmailSubmitedFromCookies,
    }
  }, [
    appInitialized,
    isScEnabled,
    isScEnabledFromCookies,
    isLoggedIn,
    isLoggedInFromCookies,
    isSCBannerClickedFromCookies,
    isWelcomeEmailSubmited,
    isWelcomeEmailSubmitedFromCookies,
  ])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const AuthProviderMemo = memo(AuthProvider)

function useAuth() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthProviderMemo as AuthProvider, useAuth }
