export const LANGUAGES = [...process.env.LANGUAGES] || ['en']
export const PRISMIC_LANGUAGES = [...process.env.PRISMIC_LANGUAGES] || ['en-us']
export const LANGUAGE_COOKIE_NAME = 'language'

export const i18n = {
  defaultLocale: LANGUAGES[0],
  locales: LANGUAGES,
  prismicLocales: PRISMIC_LANGUAGES,
} as const

export type Locale = (typeof i18n)['locales'][number]
