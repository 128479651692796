'use client'

import { useParams, usePathname, useRouter as useNextRouter } from 'next/navigation'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useRef } from 'react'
import { getLanguageHref } from 'app/utils/getLanguageHref'
import ROUTES from 'temp/routes.json'
import NProgress from 'nprogress'
import { useAuth } from 'app/context/auth'

type NavigationContextType = {
  pushWithCallback: (path: string, callback?: () => void, language?: string) => void
  goBack: () => void
}

const NavigationContext = createContext<NavigationContextType>(null)

type NavigationProviderProps = {
  children: ReactNode
}

const NavigationProvider = ({ children }: NavigationProviderProps) => {
  const { locale } = useParams()
  const pathname = usePathname()
  const callbacks = useRef<any[]>([])
  const navHistory = useRef<string[]>([])
  const { push, back } = useNextRouter()
  const { isLoggedIn } = useAuth()

  useEffect(() => {
    if (!navHistory.current.includes(pathname) && navHistory.current.length < 2) {
      navHistory.current.push(pathname)
    }

    for (const callback of callbacks.current) {
      callback?.()
      callbacks.current.splice(callbacks.current.indexOf(callback), 1)
    }

    NProgress.done()
  }, [pathname])

  const pushWithCallback = (path: string, callback?: () => void, language?: string) => {
    callbacks.current = [...callbacks.current, callback]
    const externalHref = path?.startsWith('http')
    const languageHref = getLanguageHref({ href: path, language, locale })

    if (externalHref) {
      push(path)
    } else {
      NProgress.configure({ showSpinner: false }).start()
      push(languageHref)
    }
  }

  const goBack = () => {
    const inAppNavigation = navHistory.current.length > 1
    const isOutsideReferrer = document.referrer === ''

    NProgress.configure({ showSpinner: false }).start()

    if (isOutsideReferrer && !inAppNavigation) {
      push(ROUTES.LANDING_PAGE)

      return
    }

    if (inAppNavigation || !isLoggedIn) {
      back()

      return
    }

    if (isLoggedIn) {
      push(ROUTES.HOME)
    }
  }

  return (
    <NavigationContext.Provider
      value={{
        pushWithCallback,
        goBack,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

function useRouter() {
  const { pushWithCallback, goBack } = useContext(NavigationContext)
  const nextRouter = useNextRouter()

  if (pushWithCallback === undefined || !goBack) {
    throw new Error('useRouterPush must be used within a NavigationProvider')
  }

  return {
    ...nextRouter,
    push: pushWithCallback,
    back: goBack,
  }
}

export { NavigationContext, NavigationProvider, useRouter }
