import getParamFromString from '@patrianna/shared-utils/getParamFromString'
import { trackAnalyticEvent } from '@patrianna-payments/analytics/main'
import type { AnalyticOptionsArg } from '@patrianna-payments/analytics/main/types'
import { identifyAnalytic } from '@patrianna-payments/analytics/main/events'

const gtm = {
  auth: getParamFromString(process.env.GTM_ENV_KEY || '', 'gtm_auth'),
  preview: getParamFromString(process.env.GTM_ENV_KEY || '', 'gtm_preview'),
  containerId: process.env.GTM_KEY,
}

export const analyticConfig = {
  debug: false,
  app: process.env.BRAND_NAME,
  analytics: {
    gtm,
    bloomreach: true,
  },
}

export type AnalyticOptions = AnalyticOptionsArg<(typeof analyticConfig)['analytics']> & { all?: boolean }

export const defaultAnalyticOptions: AnalyticOptions = {
  all: false,
  gtm: true,
}

export const trackEvent = <T = unknown>(...args: Parameters<typeof trackAnalyticEvent<AnalyticOptions, T>>) =>
  trackAnalyticEvent<(typeof analyticConfig)['analytics'], T>(...args)

export const identify = (...args: Parameters<typeof identifyAnalytic<AnalyticOptions>>) =>
  identifyAnalytic<(typeof analyticConfig)['analytics']>(...args)
