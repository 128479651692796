import type { CountryConfig } from '.'

const storeBenefitsTitle =
  'This option show/hide block with Store Benefits. This option works only when  user logged in and SC mode enabled'

type ConfigItem = Omit<CountryConfig, 'title'>

const primaryConfig: ConfigItem = {
  storeBenefits: {
    title: storeBenefitsTitle,
    enabled: true,
  },
  liveChatFeature: {
    title: 'Shop tile block - common.live_chat_access',
    enabled: true,
  },
  phoneSupportFeature: {
    title: 'Shop tile block - shop.phoneSupport',
    enabled: false,
  },
  profilePageFeature: {
    title: 'Show or hide profile page',
    enabled: true,
  },
  goldCoinsPageFeature: {
    title: 'Show or hide gold-coins page',
    enabled: true,
  },
  socialLinksFeature: {
    title: 'Show/hide FB, Twitter etc. social links in footer and menu',
    enabled: true,
  },
  extraCoinsBannerFeature: {
    title: 'Show/hide extra coins banner on home page',
    enabled: true,
  },
  loyaltyProgramFeature: {
    title: 'Enable/disable loyalty program feature',
    enabled: true,
  },
  referFriendFeature: {
    title: 'Enable/disable Refer a Friend feature and SC mode enabled',
    enabled: true,
  },
  paymentSupportNumbersPrimaryFeature: {
    title: 'Enable/disable payment support numbers primary block in footer',
    enabled: true,
    label: 'footer.tel_us',
    numbers: 'footer.country_tel_US',
  },
  paymentSupportNumbersSecondaryFeature: {
    title: 'Enable/disable payment support numbers secondary block in footer',
    enabled: true,
    label: 'footer.tel_eu',
    numbers: 'footer.country_tel_EU',
  },
  helpCentreFeature: {
    title: 'Enable/disable Help Center feature',
    enabled: true,
  },
  termsContentFeature: {
    title: 'Key name for termsContents',
    ruleName: 'TC-GC',
  },
  levelUpHandlerFeature: {
    title: 'Enable/disable level up handler',
    enabled: true,
  },
  vipWeeklyRewardFeature: {
    title: 'Enable/disable VIP weekly reward',
    enabled: true,
  },
  lowOnCoinsFeature: {
    title: 'Enable/disable low on coins handler',
    enabled: true,
  },
  exclusiveCategoryFeature: {
    title: 'Enable/disable exclusive category',
    enabled: true,
  },
  aboutPageContentFeature: {
    title: 'About page content',
    url: process.env.ABOUT_PAGE,
  },
  exclusiveGcPageFeature: {
    title: 'Enable/disable exclusive GC page',
    enabled: true,
  },
  privacyPageContentFeature: {
    title: 'Privacy page content',
    url: 'PP-GC',
  },
  privacyPageCaliforniaContentFeature: {
    title: 'Privacy page content',
    url: process.env.PRIVACY_PAGE_CALIFORNIA,
  },
  specialOffersFeature: {
    title: 'Enable/disable special offers feature',
    enabled: true,
  },
  freeAddOnsFeature: {
    title: 'Enable/disable free add-ons feature',
    enabled: true,
  },
  responsiblePlayContentFeature: {
    title: 'Responsible Play page content',
    url: process.env.RESPONSIBLE_PLAY_PAGE,
  },
  bannersFeature: {
    title: 'Enable/disable banners feature',
    enabled: true,
  },
  dailyRewardFeature: {
    title: 'Enable/disable daily reward feature',
    enabled: true,
  },
  footerGamesMenuFeature: {
    title: 'Enable/disable footer games menu feature',
    enabled: true,
  },
  countryValidatorFeature: {
    title: 'Enable/disable country validator feature',
    enabled: true,
  },
  formStatesValueFeature: {
    title: 'Form states value',
    country: 'US',
  },
  formSelectFieldFeature: {
    title: 'Enable/disable form select field feature',
    enabled: true,
  },
}

const secondaryConfig: ConfigItem = {
  ...primaryConfig,
  phoneSupportFeature: {
    title: 'Shop tile block - shop.phoneSupport',
    enabled: true,
  },
  storeBenefits: {
    title: storeBenefitsTitle,
    enabled: false,
  },
  liveChatFeature: {
    title: 'Shop tile block - common.live_chat_access',
    enabled: false,
  },
  extraCoinsBannerFeature: {
    title: 'Show/hide extra coins banner on home page',
    enabled: false,
  },
  loyaltyProgramFeature: {
    title: 'Enable/disable loyalty program feature',
    enabled: false,
  },
  referFriendFeature: {
    title: 'Enable/disable Refer a Friend feature',
    enabled: false,
  },
  paymentSupportNumbersPrimaryFeature: {
    title: 'Enable/disable payment numbers support primary block in footer',
    enabled: false,
  },
  paymentSupportNumbersSecondaryFeature: {
    title: 'Enable/disable payment numbers support secondary block in footer',
    enabled: true,
    label: 'footer.tel_title',
    numbers: 'footer.country_tel_EU',
  },
  termsContentFeature: {
    title: 'Key name for termsContents',
    ruleName: 'TC-GC-NON-US',
  },
  levelUpHandlerFeature: {
    title: 'Enable/disable level up handler',
    enabled: false,
  },
  vipWeeklyRewardFeature: {
    title: 'Enable/disable VIP weekly reward',
    enabled: false,
  },
  exclusiveCategoryFeature: {
    title: 'Enable/disable exclusive category',
    enabled: false,
  },
  aboutPageContentFeature: {
    title: 'About page content',
    url: process.env.ABOUT_PAGE_NON_US,
  },
  exclusiveGcPageFeature: {
    title: 'Enable/disable exclusive GC page',
    enabled: false,
  },
  privacyPageContentFeature: {
    title: 'Privacy page content',
    url: 'PP-GC-NON-US',
  },
  privacyPageCaliforniaContentFeature: {
    title: 'Privacy page content',
    url: process.env.PRIVACY_PAGE_CALIFORNIA_NON_US,
  },
  specialOffersFeature: {
    title: 'Enable/disable special offers feature',
    enabled: false,
  },
  freeAddOnsFeature: {
    title: 'Enable/disable free add-ons feature',
    enabled: false,
  },
  responsiblePlayContentFeature: {
    title: 'Responsible Play page content',
    url: process.env.RESPONSIBLE_PLAY_PAGE_NON_US,
  },
  bannersFeature: {
    title: 'Enable/disable banners feature',
    enabled: false,
  },
  dailyRewardFeature: {
    title: 'Enable/disable daily reward feature',
    enabled: false,
  },
  footerGamesMenuFeature: {
    title: 'Enable/disable footer games menu feature',
    enabled: false,
  },
  countryValidatorFeature: {
    title: 'Enable/disable country validator feature',
    enabled: false,
  },
  formStatesValueFeature: {
    title: 'Form states value',
    country: '',
  },
  formSelectFieldFeature: {
    title: 'Enable/disable form select field feature',
    enabled: false,
  },
}

const allCountryConfigs: { [key: string]: CountryConfig } = {
  US: {
    title: 'Config for US country users',
    ...primaryConfig,
  },
  NZ: {
    title: 'Config for New Zeland country users',
    ...secondaryConfig,
  },
  ZA: {
    title: 'Config for ZA country users',
    ...secondaryConfig,
  },
  TH: {
    title: 'Config for TH country users',
    ...secondaryConfig,
  },
  CA: {
    title: 'Config for Canada country users',
    ...secondaryConfig,
    profilePageFeature: {
      title: 'Return 404 for non supported coutnries for logged out user',
      enabled: false,
    },
    goldCoinsPageFeature: {
      title: 'Show or hide gold-coins page',
      enabled: false,
    },
    helpCentreFeature: {
      title: 'Enable/disable Help Center feature',
      enabled: false,
    },
    socialLinksFeature: {
      title: 'Show/hide FB, Twitter etc. social links in footer and menu',
      enabled: false,
    },
    paymentSupportNumbersSecondaryFeature: {
      title: 'Enable/disable payment numbers support secondary block in footer',
      enabled: false,
      label: 'footer.tel_title',
      numbers: 'footer.country_tel_CA',
    },
    lowOnCoinsFeature: {
      title: 'Enable/disable low on coins handler',
      enabled: false,
    },
  },
  AU: {
    title: 'Config for Australia country users',
    ...secondaryConfig,
    profilePageFeature: {
      title: 'Return 404 for non supported coutnries for logged out user',
      enabled: false,
    },
    goldCoinsPageFeature: {
      title: 'Show or hide gold-coins page',
      enabled: false,
    },
    helpCentreFeature: {
      title: 'Enable/disable Help Center feature',
      enabled: false,
    },
    socialLinksFeature: {
      title: 'Show/hide FB, Twitter etc. social links in footer and menu',
      enabled: false,
    },
    paymentSupportNumbersSecondaryFeature: {
      title: 'Enable/disable payment numbers support secondary block in footer',
      enabled: false,
      label: 'footer.tel_title',
      numbers: 'footer.country_tel_EU',
    },
    lowOnCoinsFeature: {
      title: 'Enable/disable low on coins handler',
      enabled: false,
    },
  },
  'no-country': {
    title: 'Default fallback config for logged out users and rest of coutries.',
    storeBenefits: {
      title: storeBenefitsTitle,
      enabled: false,
    },
    liveChatFeature: {
      title: 'Shop tile block - common.live_chat_access',
      enabled: false,
    },
    phoneSupportFeature: {
      title: 'Shop tile block - shop.phoneSupport',
      enabled: false,
    },
    profilePageFeature: {
      title: 'Return 404 for non supported coutnries for logged out user',
      enabled: false,
    },
    goldCoinsPageFeature: {
      title: 'Show or hide gold coins page',
      enabled: false,
    },
    socialLinksFeature: {
      title: 'Show/hide FB, Twitter etc. social links in footer and menu',
      enabled: false,
    },
    extraCoinsBannerFeature: {
      title: 'Show/hide extra coins banner on home page',
      enabled: false,
    },
    loyaltyProgramFeature: {
      title: 'Enable/disable loyalty program feature',
      enabled: false,
    },
    referFriendFeature: {
      title: 'Enable/disable Refer a Friend feature',
      enabled: false,
    },
    helpCentreFeature: {
      title: 'Enable/disable Help Center feature',
      enabled: false,
    },
    paymentSupportNumbersPrimaryFeature: {
      title: 'Enable/disable payment numbers support primary block in footer',
      enabled: false,
    },
    paymentSupportNumbersSecondaryFeature: {
      title: 'Enable/disable payment numbers support secondary block in footer',
      enabled: false,
    },
    termsContentFeature: {
      title: 'Key name for termsContents',
      ruleName: 'TC-GC-NON-US',
    },
    levelUpHandlerFeature: {
      title: 'Enable/disable level up handler',
      enabled: false,
    },
    vipWeeklyRewardFeature: {
      title: 'Enable/disable VIP weekly reward',
      enabled: false,
    },
    lowOnCoinsFeature: {
      title: 'Enable/disable low on coins handler',
      enabled: false,
    },
    exclusiveCategoryFeature: {
      title: 'Enable/disable exclusive category',
      enabled: false,
    },
    aboutPageContentFeature: {
      title: 'About page content',
      url: process.env.ABOUT_PAGE_NON_US,
    },
    exclusiveGcPageFeature: {
      title: 'Enable/disable exclusive GC page',
      enabled: false,
    },
    privacyPageContentFeature: {
      title: 'Privacy page content',
      url: 'PP-GC-NON-US',
    },
    privacyPageCaliforniaContentFeature: {
      title: 'Privacy page content',
      url: process.env.PRIVACY_PAGE_CALIFORNIA_NON_US,
    },
    specialOffersFeature: {
      title: 'Enable/disable special offers feature',
      enabled: false,
    },
    freeAddOnsFeature: {
      title: 'Enable/disable free add-ons feature',
      enabled: false,
    },
    responsiblePlayContentFeature: {
      title: 'Responsible Play page content',
      url: process.env.RESPONSIBLE_PLAY_PAGE_NON_US,
    },
    bannersFeature: {
      title: 'Enable/disable banners feature',
      enabled: false,
    },
    dailyRewardFeature: {
      title: 'Enable/disable daily reward feature',
      enabled: false,
    },
    footerGamesMenuFeature: {
      title: 'Enable/disable footer games menu feature',
      enabled: false,
    },
    countryValidatorFeature: {
      title: 'Enable/disable country validator feature',
      enabled: false,
    },
    formStatesValueFeature: {
      title: 'Form states value',
      country: '',
    },
    formSelectFieldFeature: {
      title: 'Enable/disable form select field feature',
      enabled: false,
    },
  },
}

export default allCountryConfigs
